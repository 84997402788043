<template>
    <div class="wrap">
        <!-- 面包屑 -->
        <b-container class="navtab">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
                <el-breadcrumb-item  v-if="shopid!=''" :to="{ path: '/info',query:{shopid:shopInfoObj.id} }">
                    {{languageName=='cn'?shopInfoObj.cn_name:''}}
                    {{languageName=='en'?shopInfoObj.en_name:''}}
                    {{languageName=='it'?shopInfoObj.it_name:''}}
                </el-breadcrumb-item>
                <el-breadcrumb-item v-else :to="{ path: '/shopcar'}">{{$t('message.carshop')}}</el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span :class="inputaddressflag?'infocur_spet':'paycur_spet'" @click="inputaddressflag=false">{{$t('message.checkorderinfo')}}</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span :class="inputaddressflag?'infocur':'paycur'">{{$t('message.pay')}}</span>
                </el-breadcrumb-item>
            </el-breadcrumb>
        </b-container>
        <!-- pc布局【商品订单展示】 -->
        <b-container class="pc-shopcar">
            <div class="pc-shopwrap">
                <div class="pc-shop">
                    <div class="pc-shop-group" v-for="(item,index) in datalist" :key="index">
                        <div class="pc-shop-img">
                            <img :src="item.goods_img" alt="">
                        </div>
                        <div class="pc-shop-info">
                            <div class="pc-shop-title">
                                {{languageName=='cn'?item.cn_name:''}}
                                {{languageName=='en'?item.en_name:''}}
                                {{languageName=='it'?item.it_name:''}}    
                            </div>
                            <div class="pc-shop-numer">
                                {{languageName=='cn'?item.cn_spect_name:''}}
                                {{languageName=='en'?item.en_spect_name:''}}
                                {{languageName=='it'?item.it_spect_name:''}}
                                <span class="pc-num">&times;{{item.goods_num}}</span>
                            </div>
                            <div class="pc-shop-money">{{$t('message.m')}}{{item.totalprice}}</div>
                        </div>
                    </div>
                    <div class="pc-calcul">
                        <div class="pc-calcul-group">
                            <div class="pc-calcul-txt">{{$t('message.moenytotal')}}</div>
                            <div class="pc-calcul-num" v-show="shopDetailObj&&shopDetailObj.totalmoney">{{$t('message.m')}}{{shopDetailObj.money}}</div>
                        </div>
                        <!-- 邮费 -->
                        <div class="pc-calcul-group">
                            <div class="pc-calcul-txt">{{$t('message.postmoneys')}}</div>
                            <div class="pc-calcul-num" v-show="payamount">{{$t('message.m')}}{{payamount}}<span class="pc-allnum">({{$t('message.fullpay')}}{{$t('message.m')}}{{amount}}{{$t('message.mpay')}}{{$t('message.freepay')}})</span></div>
                        </div>
                        <div class="pc-calcul-group" v-show="(shopDetailObj&&shopDetailObj.totalaams!='0.00')">
                            <div class="pc-calcul-txt">AAMS</div>
                            <div class="pc-calcul-num">{{$t('message.m')}}{{shopDetailObj.totalaams}}</div>
                        </div>
                        <div class="pc-calcul-group" v-show="(shopDetailObj&&shopDetailObj.totaliva!='0.00')">
                            <div class="pc-calcul-txt">IVA</div>
                            <div class="pc-calcul-num">{{$t('message.m')}}{{shopDetailObj.totaliva}}</div>
                        </div>
                    </div>
                    <!-- 应付总额 -->
                    <div class="pc-calcul-group">
                        <div class="pc-calcul-txt">{{$t('message.paymoenyed')}}</div>
                        <div class="pc-calcul-nums" v-show="shopDetailObj&&shopDetailObj.totalmoney">{{$t('message.m')}}{{shopDetailObj.totalmoney}}</div>
                    </div>
                </div>
                <!-- 有默认地址时，展示默认地址并可以立即支付 -->
                <div class="pc-all" v-show="inputaddressflag">
                    <div class="pc-title">{{$t('message.shoaddress')}}</div>
                    <div class="pc-input">
                        <div class="address-show">{{checkAddress}}</div>
                        <span class="pc-update" @click="getAddressList">{{$t('message.update')}}</span>
                    </div>
                    <div class="pc-title">{{$t('message.pay')}}</div>
                    <div class="pc-input_odd">
                        <div class="pc-pay-type" v-for="(item,index) in paylist" :key="index">
                            <div class="pc-pay-otem"><el-radio v-model="radio" :label="item.id">{{item.name}}</el-radio><span v-show="item.id==4&&(payOther!='0'||payOther!='0.0'||payOther!='0.00')">{{ item.id==4?$t('message.shouxufei')+`${ payOther }`+$t('message.shouxufei2'):'' }}</span></div>
                        </div>
                    </div>
                    <div class="pc-link">
                        <span class="pc-linkbtn" @click="getSubmit('money','web')">{{$t('message.paytotal')}}</span>
                    </div>
                </div>
                <!-- 默认无地址时，先提交地址 -->
                <div class="pc-all-address" v-show="!inputaddressflag">
                    <div class="pc-title">{{$t('message.shoaddress')}}</div>
                    <div class="pc-select">
                        <div class="pc-one_select">
                            <div class="pc-one_select-name" @click="checkAddressFlag=!checkAddressFlag">{{checkAddress?checkAddress:$t('message.newaddress')}}</div>
                            <div class="pc-one_select-list" v-show="checkAddressFlag">
                                <div :value="$t('message.newaddress')" @click="getQueryItem()">{{$t('message.newaddress')}}</div>
                                <!-- <div value="" v-for="(item,index) in tableData" :key="index" @click="getQueryItem(item)">{{item.info}}，{{item.apartment}}，{{item.city}}，{{item.province}}</div> -->
                                <div value="" v-for="(item,index) in tableData" :key="index" @click="getQueryItem(item)">{{item.info}}，{{item.city}}，{{item.province}}</div>
                            </div>
                        </div>
                        <span class="el-icon-arrow-down"></span>
                    </div>
                    <div class="pc-select">
                        <div class="pc-select-tit">{{$t('message.conuycity')}}</div>
                        <select class="pc-select">
                            <option value="Italia">Italia</option>
                        </select>
                        <span class="el-icon-arrow-down"></span>
                    </div>
                    <div class="pc-input pc-input_odd"><input type="text" :placeholder="$t('message.province')" v-model="addressParamsObj.province" @blur="blurText" /></div>
                    <div class="pc-input-two">
                        <input type="text" :placeholder="$t('message.name')" v-model="addressParamsObj.name"/>
                        <div class="pc-t"></div>
                        <input type="text" :placeholder="$t('message.surname')" v-model="addressParamsObj.family_name"/>
                    </div>
                    <div class="pc-input"><input type="text" :placeholder="$t('message.address')" v-model="addressParamsObj.info" /></div>
                    <div style="height:20px"></div>
                    <!-- <div class="pc-input"><input type="text" :placeholder="$t('message.doornum')" v-model="addressParamsObj.apartment" /></div>
                    <div class="pc-input pc-input_odd"><input type="text" :placeholder="$t('message.doorling')" v-model="addressParamsObj.doorbell" /></div> -->
                    <div class="pc-input-two">
                        <input type="text" :placeholder="$t('message.city')" v-model="addressParamsObj.city"/>
                        <div class="pc-t"></div>
                        <input type="text" :placeholder="$t('message.postcode')" @blur="blurTextCode" v-model="addressParamsObj.postcode"/>
                    </div>
                    <div class="pc-input"><input type="text" :placeholder="$t('message.telep')" v-model="addressParamsObj.mobile" /></div>
                    <div class="pc-link">
                        <span class="pc-linkbtn" @click="getSubmit('order')">{{$t('message.suborder')}}</span>
                    </div>
                </div>
            </div>
        </b-container>
        <!-- wap布局【商品订单展示】 -->
        <!-- wap布局【商品和地址】 -->
        <!-- wap布局【商品和和支付方式】 -->
        <b-container class="wap-shopcar">
            <b-row class="wap-shop-show">
                <b-col class="wap-shop-showbtn" @click="shopShowFlag = !shopShowFlag">{{$t('message.waptitle')}}<span class="el-icon-arrow-down"></span></b-col>
                <b-col class="wap-shop-showmoney" v-show="shopDetailObj&&shopDetailObj.totalmoney">{{$t('message.m')}}{{shopDetailObj.totalmoney}}</b-col>
            </b-row>
            <div class="wap-shop-wrap" v-show="shopShowFlag">
                <div class="wap-shop-group" v-for="(item,index) in datalist" :key="index">
                    <div class="wap-shop-img"><img :src="item.goods_img" alt=""></div>
                    <div class="wap-shop-info">
                        <div class="wap-shop-title">
                            {{languageName=='cn'?item.cn_name:''}}
                            {{languageName=='en'?item.en_name:''}}
                            {{languageName=='it'?item.it_name:''}}    
                        </div>
                        <div class="wap-shop-numer">
                            {{languageName=='cn'?item.cn_spect_name:''}}
                            {{languageName=='en'?item.en_spect_name:''}}
                            {{languageName=='it'?item.it_spect_name:''}}
                            <span class="wap-num">&times;{{item.goods_num}}</span>
                        </div>
                        <div class="wap-shop-money">{{$t('message.m')}}{{item.totalprice}}</div>
                    </div>
                </div>
                <div class="wap-calcul">
                    <div class="wap-calcul-group">
                        <div class="wap-calcul-txt">{{$t('message.moenytotal')}}</div>
                        <div class="wap-calcul-num" v-show="shopDetailObj&&shopDetailObj.totalmoney">{{$t('message.m')}}{{shopDetailObj.money}}</div>
                    </div>
                    <!-- 邮费 -->
                    <div class="wap-calcul-group">
                        <div class="wap-calcul-txt">{{$t('message.postmoneys')}}</div>
                        <div class="wap-calcul-num" v-show="payamount">{{$t('message.m')}}{{payamount}}<span class="wap-allnum">({{$t('message.fullpay')}}{{$t('message.m')}}{{amount}}{{$t('message.mpay')}}{{$t('message.freepay')}})</span></div>
                    </div>
                    <div class="wap-calcul-group" v-show="(shopDetailObj&&shopDetailObj.totalaams!='0.00')">
                        <div class="wap-calcul-txt">AAMS</div>
                        <div class="wap-calcul-num">{{$t('message.m')}}{{shopDetailObj.totalaams}}</div>
                    </div>
                    <div class="wap-calcul-group" v-show="(shopDetailObj&&shopDetailObj.totaliva!='0.00')">
                        <div class="wap-calcul-txt">IVA</div>
                        <div class="wap-calcul-num">{{$t('message.m')}}{{shopDetailObj.totaliva}}</div>
                    </div>
                </div>
                <!-- 应付总额 -->
                <div class="wap-calcul-group">
                    <div class="wap-calcul-txt">{{$t('message.paymoenyed')}}</div>
                    <div class="wap-calcul-nums" v-show="shopDetailObj&&shopDetailObj.totalmoney">{{$t('message.m')}}{{shopDetailObj.totalmoney}}</div>
                </div>
            </div>
            <div class="wap-all" v-show="inputaddressflag">
                <div class="wap-title">{{$t('message.shoaddress')}}</div>
                <div class="wap-input">
                    <div class="address-show">{{checkAddress}}</div>
                    <span class="wap-update" @click="inputaddressflag=!inputaddressflag">{{$t('message.update')}}</span>
                </div>
                <div class="wap-title">{{$t('message.pay')}}</div>
                <div class="wap-input_odd">
                    <div class="wap-pay-type" v-for="(item,index) in paylist" :key="index">
                        <div class="wap-pay-otem" v-show="item.id!='1'"><el-radio v-model="radio" :label="item.id">{{item.name}}</el-radio><span v-show="item.id==4&&(payOther!='0'||payOther!='0.0'||payOther!='0.00')">{{ item.id==4?$t('message.shouxufei')+`${ payOther }`+$t('message.shouxufei2'):'' }}</span></div>
                    </div>
                </div>
                <div class="wap-link">
                    <span class="wap-linkbtn" @click="getSubmit('money','mobile')">{{$t('message.paytotal')}}</span>
                </div>
            </div>
            <div class="pc-all-address" v-show="!inputaddressflag">
                <div class="pc-title">{{$t('message.shoaddress')}}</div>
                <div class="pc-select">
                    <div class="pc-one_select">
                        <div class="pc-one_select-name" @click="checkAddressFlag=!checkAddressFlag">{{checkAddress?checkAddress:$t('message.newaddress')}}</div>
                        <div class="pc-one_select-list" v-show="checkAddressFlag">
                            <div :value="$t('message.newaddress')" @click="getQueryItem()">{{$t('message.newaddress')}}</div>
                            <!-- <div value="" v-for="(item,index) in tableData" :key="index" @click="getQueryItem(item)">{{item.info}}，{{item.apartment}}，{{item.city}}，{{item.province}}</div> -->
                            <div value="" v-for="(item,index) in tableData" :key="index" @click="getQueryItem(item)">{{item.info}}，{{item.city}}，{{item.province}}</div>
                        </div>
                    </div>
                    <span class="el-icon-arrow-down"></span>
                </div>
                <div class="pc-select">
                    <div class="pc-select-tit">{{$t('message.conuycity')}}</div>
                    <select class="pc-select">
                        <option value="Italia">Italia</option>
                    </select>
                    <span class="el-icon-arrow-down"></span>
                </div>
                <div class="pc-input pc-input_odd"><input type="text" :placeholder="$t('message.province')" v-model="addressParamsObj.province" @blur="blurText"/></div>
                <div class="pc-input"><input type="text" :placeholder="$t('message.name')" v-model="addressParamsObj.name" /></div>
                <div class="pc-input"><input type="text" :placeholder="$t('message.surname')" v-model="addressParamsObj.family_name" /></div>
                <div class="pc-input"><input type="text" :placeholder="$t('message.address')" v-model="addressParamsObj.info" /></div>
                <!-- <div class="pc-input"><input type="text" :placeholder="$t('message.doornum')" v-model="addressParamsObj.apartment" /></div>
                <div class="pc-input pc-input_odd"><input type="text" :placeholder="$t('message.doorling')" v-model="addressParamsObj.doorbell" /></div> -->
                <div class="pc-input"><input type="text" :placeholder="$t('message.city')" v-model="addressParamsObj.city" /></div>
                <div class="pc-input"><input type="text" :placeholder="$t('message.postcode')" @blur="blurTextCode" v-model="addressParamsObj.postcode" /></div>
                <div class="pc-input"><input type="text" :placeholder="$t('message.telep')" v-model="addressParamsObj.mobile" /></div>
                <div class="pc-link">
                    <span class="pc-linkbtn" @click="getSubmit('order')">{{$t('message.suborder')}}</span>
                </div>
            </div>
        </b-container>
    </div>
</template>
<script>
import ElementUI from 'element-ui';
import {jsReg,yooz_lang,getLocalItem,delLocalItem} from '@/untils/common';
export default {
    name:'shopfrom',
    data() {
        return {
            token:'',
            amount:'',
            payamount:'',
            shopid:'',
            shoptypeid:'',
            shopInfoObj:{
                // "id": 44,
                // "goods_type_id": 35,
                // "goods_type_two_id": 39,
                // "goods_name": "悦刻 INFINITY 设备",
                // "goods_name2": "悦刻  INFINITY 设备-副名称",
                // "cn_name": "悦刻 INFINITY 设备",
                // "en_name": "Yueke  INFINITY device",
                // "it_name": "Dispositivo  INFINITY Yueke",
                // "cn_name2": "悦刻  INFINITY 设备-副名称",
                // "en_name2": null,
                // "it_name2": null,
                // "title": "悦刻 INFINITY 设备",
                // "cn_title": null,
                // "en_title": null,
                // "it_title": null,
                // "subtitle": "悦刻 INFINITY 设备-副标题",
                // "cn_subtitle": "悦刻 INFINITY 设备-副标题",
                // "en_subtitle": null,
                // "it_subtitle": null,
                // "is_home": 1,
                // "profile": "机器流畅的性能",
                // "cn_profile": "机器流畅的性能",
                // "en_profile": null,
                // "it_profile": null,
                // "goods_img": "http://tatake.oss-cn-beijing.aliyuncs.com/62a5538003b02.png",
                // "goods_imgs": "http://tatake.oss-cn-beijing.aliyuncs.com/62a550ee70c0f.png,http://tatake.oss-cn-beijing.aliyuncs.com/62a550eea9e8b.png,http://tatake.oss-cn-beijing.aliyuncs.com/62a550eebaa21.png,http://tatake.oss-cn-beijing.aliyuncs.com/62a550ee91173.png",
                // "content": "",
                // "cn_content": null,
                // "en_content": null,
                // "it_content": null,
                // "price": "6.00",
                // "status": 1,
                // "order_sum": 0,
                // "create_time": "2022-06-05 16:52:45",
                // "prime_index": 0,
                // "fanwei": "<img src=\"/static/kindeditor/attached/image/20220612/20220612213319_69026.png\" width=\"100%\" alt=\"\" />",
                // "ganshou": "<img src=\"/static/kindeditor/attached/image/20220612/20220612213332_46425.png\" width=\"100%\" alt=\"\" />",
                // "chengfen": "<img src=\"/static/kindeditor/attached/image/20220612/20220612213341_89079.png\" width=\"100%\" alt=\"\" />",
                // "use_method": "<img src=\"/static/kindeditor/attached/image/20220612/20220612213357_85097.png\" width=\"100%\" alt=\"\" />",
                // "sort": 4,
                // "cn_price": "6.00",
                // "en_price": "6.00",
                // "it_price": "6.00",
                // "discounts": 0
            },
            paylist:[],
            languageName:'',
            languageObj:{},
            inputaddressflag:false,
            radio:'1',
            value1:'',
            addressParamsObj:{
                is_default:1,//1是选中，0是未选中
                country:'it',//'意大利'（国家 添加时必填）、
                province:'',//
                city:'',//（城市 添加时必填）、
                info:'',//（详细地址 添加时必填）、
                family_name:'',//（姓 添加时必填）、
                name:'',//（名 添加时必填）
                mobile:'',//（手机号 添加时必填）、
                // apartment:'',//（公寓、门牌号 添加时必填）、
                // doorbell:'',//（门铃 添加时必填）
                postcode:'',//（邮编 添加时必填）、
            },
            checkAddressFlag:false,
            checkAddress:'',
            tableData:[],
            allMoney:0,//总计金额
            datalistarr:[],
            datalist:[],
            size:10,
            orderno:'',
            routesParams:{},
            oneShopInfoObj:{},
            carShopInfoObj:{},
            shopShowFlag:false,
            shopDetailObj:{},
            userPople:'',//userPople:'company' 企业用户，数量10+
            payOther:'',//手续费
        }
    },
    methods: {
        blurText(){
            this.$http.api_datalist_getzipcodebyprovince({
                province:this.addressParamsObj.province,
                country:'it',//this.languageName
            }).then((res)=>{
                if(res){
                    this.addressParamsObj.postcode = '';
                    if(res.code==1){
                        if(res.data){
                            this.addressParamsObj.postcode = res.data.zip_code;
                        }
                    }else{
                        ElementUI.Message({
                            message:this.languageObj.province_codepost,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
        blurTextCode(){
            this.$http.api_datalist_getprovincebyzipcode({
                zipcode:this.addressParamsObj.postcode,//邮编
                // country:this.languageName
            }).then((res)=>{
                if(res){
                    this.addressParamsObj.province = '';
                    if(res.code==1){
                        if(res.data&&res.data.length>0){
                            this.addressParamsObj.province = res.data[0].province;
                        }
                    }else{
                        ElementUI.Message({
                            message:this.languageObj.codepost_province,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
        getAddressList(){
            this.inputaddressflag=!this.inputaddressflag;
            this.getAddresslist(1);
        },
        // 获取系统保底邮费和平台邮费
        getQueryPostage(){
            this.$http.api_order_getpostage().then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.payamount = res.data.pay_postage;
                        }
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
        // 获取国家包邮金额范围
        getPackAmount(){
            this.languageName = localStorage.lang?localStorage.lang:yooz_lang;
            if(this.languageName){
                this.$http.api_order_getpackagemailamount({
                    country:this.languageName//国家编码
                }).then((res)=>{
                    if(res){
                        if(res.code==1){
                            if(res.data){
                                this.amount = res.data.amount;
                            }
                        }else{
                            ElementUI.Message({
                                message:res.message,
                                type:'error',
                                center: true,
                                offset:10,
                            });
                            return false;
                        }
                    }
                });
            }
        },
        // 获取支付类型
        getPayTpye(){
            this.$http.api_order_getpricetype().then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            var paylistobj = res.data;
                            var paylistarr = [];
                            for(let i in paylistobj) {
                                paylistarr.push({id:i,name:paylistobj[i],check:false});
                            }
                            this.paylist = paylistarr;
                        }
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
        //新增获取货到付款的手续费接口
        getPayOther(){
            
            this.$http.api_datalist_getcashdelivery({token:this.token}).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            if(res.data.cashdelivery){
                                this.payOther = res.data.cashdelivery;
                            }
                        }
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
        // 查看当前地址并编辑
        getQueryItem(item){
            if(this.token){
                if(item){
                    this.addressParamsObj = item;
                    // this.checkAddress = `${item.info}，${item.apartment}，${item.city}，${item.province}`
                    this.checkAddress = `${item.info}，${item.city}，${item.province}`
                }else{
                    this.addressParamsObj = {
                        is_default:1,//1是选中，0是未选中
                        country:'it',//（国家 添加时必填）、
                    };
                }
                this.checkAddressFlag = !this.checkAddressFlag;
            }else{
                ElementUI.Message({
                    message:this.languageObj.logintip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }
        },
        // 获取地址列表数据
        getAddresslist(page){
            if(this.token){
                let params = {
                    token:this.token,
                    page:page,
                    size:this.size
                }
                this.$http.api_user_addresslist(params).then((res)=>{
                    if(res){
                        if(res.code==1){
                            if(res.data){
                                this.tableData = res.data.list;
                            }
                        }else if(res.code==-1){
                            this.$store.commit('setLoginNameStatus', {});
                            delLocalItem('login_user_info');
                            this.$root.Bus.$emit('userInfoClick', {});
                            this.$store.commit('setLoginStatus',true);
                            this.$router.push({path:'/'});
                        }else{
                            // ElementUI.Message({
                            //     message:res.message,
                            //     type:'error',
                            //     center: true,
                            //     offset:10,
                            // });
                            // return false;
                        }
                    }
                })
            }else{
                ElementUI.Message({
                    message:this.languageObj.logintip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }
        },
        // 查询收货默认展示收货地址信息
        getAddressDefault(token){
            this.$http.api_user_addressdefault({
                token:token
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.inputaddressflag = true;
                            this.addressParamsObj = res.data;
                            // this.checkAddress = `${this.addressParamsObj.info}，${this.addressParamsObj.apartment}，${this.addressParamsObj.city}，${this.addressParamsObj.province}`;
                            this.checkAddress = `${this.addressParamsObj.info}，${this.addressParamsObj.city}，${this.addressParamsObj.province}`;
                        }
                    }else if(res.code==-1){
                        this.$store.commit('setLoginNameStatus', {});
                        delLocalItem('login_user_info');
                        this.$root.Bus.$emit('userInfoClick', {});
                        this.$store.commit('setLoginStatus',true);
                        this.$router.push({path:'/'});
                    }else{
                        // ElementUI.Message({
                        //     message:res.message,
                        //     type:'error',
                        //     center: true,
                        //     offset:10,
                        // });
                    }
                }
            });
        },
        getOrderInfo(token,orderno){
            // 获取订单详情
            this.$http.api_order_getorderinfo({
                token:token,
                orderno:orderno
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            // res.data.totalaams
                            // res.data.totaliva
                            // totalaams: "2.600000"
                            // totaliva: "2.770000"
                            let aamsstr_arr = []
                            let ivastr_arr = []
                            this.shopDetailObj = res.data;
                            if(res.data.totalaams){
                                aamsstr_arr = res.data.totalaams.split('.')
                                this.shopDetailObj.totalaams = aamsstr_arr[0]+'.'+aamsstr_arr[1].slice(0,2)
                            }
                            if(res.data.totaliva){
                                ivastr_arr = res.data.totaliva.split('.')
                                this.shopDetailObj.totaliva = ivastr_arr[0]+'.'+ivastr_arr[1].slice(0,2)
                            }
                            this.shopDetailObj = res.data;
                            this.datalist = res.data.goods;
                            // 获取默认地址
                            this.getAddressDefault(token);
                        }
                    }else if(res.code==-1){
                        this.$store.commit('setLoginNameStatus', {});
                        delLocalItem('login_user_info');
                        this.$root.Bus.$emit('userInfoClick', {});
                        this.$store.commit('setLoginStatus',true);
                        this.$router.push({path:'/'});
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
        // 查询单个商品信息
        getShopInfo(shopid){
            if(shopid){
                // 商品详情接口
                this.$http.api_goods_shopinfobyid({
                    id:shopid,
                    iscompany:this.userPople=='company'?1:0,//（是否为企业身份 1是0否）
                }).then((res)=>{
                    if(res){
                        if(res.code==1){
                            if(res.data){
                                this.shopInfoObj = res.data;
                            }
                        }else{
                            ElementUI.Message({
                                message:res.message,
                                type:'error',
                                center: true,
                                offset:10,
                            });
                            return false;
                        }
                    }
                });
            }
        },
        /*
            立即付款和提交订单
        */
        getSubmit(type,tp){
            if(this.token){
                if(type=='order'){
                    // 添加收货地址
                    if(this.addressParamsObj.country==''){
                        ElementUI.Message({
                            message:this.languageObj.areatip,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }else if(this.addressParamsObj.province==''){
                        ElementUI.Message({
                            message:this.languageObj.provincetip,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }else if(this.addressParamsObj.name==''){
                        ElementUI.Message({
                            message:this.languageObj.nametip,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }else if(this.addressParamsObj.family_name==''){
                        ElementUI.Message({
                            message:this.languageObj.lasnametip,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }else if(this.addressParamsObj.info==''){
                        ElementUI.Message({
                            message:this.languageObj.addresstip,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                    // else if(this.addressParamsObj.apartment==''){
                    //     ElementUI.Message({
                    //         message:this.languageObj.apartmenttip,
                    //         type:'error',
                    //         center: true,
                    //         offset:10,
                    //     });
                    //     return false;
                    // }
                    // else if(this.addressParamsObj.doorbell==''){
                    //     ElementUI.Message({
                    //         message:this.languageObj.doortip,
                    //         type:'error',
                    //         center: true,
                    //         offset:10,
                    //     });
                    //     return false;
                    // }
                    else if(this.addressParamsObj.city==''){
                        ElementUI.Message({
                            message:this.languageObj.citytip,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }else if(this.addressParamsObj.postcode==''){
                        ElementUI.Message({
                            message:this.languageObj.postcodetip,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }else if(this.addressParamsObj.mobile==''){
                        ElementUI.Message({
                            message:this.languageObj.telepstip,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }else if(!jsReg.number.test(this.addressParamsObj.mobile)){
                        ElementUI.Message({
                            message:this.languageObj.phonerighttip,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }else{
                        let params = {
                            token:this.token,//（用户token）、
                            country:this.addressParamsObj.country,//（国家 添加时必填）、
                            province:this.addressParamsObj.province,//省 物流创建请求 此数据必填
                            city:this.addressParamsObj.city,//（城市 添加时必填）、
                            info:this.addressParamsObj.info,//（详细地址 添加时必填）、
                            family_name:this.addressParamsObj.family_name,//（姓 添加时必填）、
                            name:this.addressParamsObj.name,//（名 添加时必填）
                            mobile:this.addressParamsObj.mobile,//（手机号 添加时必填）、
                            is_default:1,//（是否为默认地址 1是 0否 默认0）、
                            // apartment:this.addressParamsObj.apartment,//（公寓、门牌号 添加时必填）、2023年9月13号改版去掉接口
                            // doorbell:this.addressParamsObj.doorbell,//（门铃 添加时必填）
                            postcode:this.addressParamsObj.postcode,//（邮编 添加时必填）、
                        }
                        if(this.addressParamsObj.id){
                            params.id = this.addressParamsObj.id;
                            params.is_edit = 1;//（是否编辑 1是 0否 默认0 is_edit、is_add 二者其一必填）
                            params.is_add = 0;//（是否添加 1是 0否 默认0 is_edit、is_add 二者其一必填）、
                        }else{
                            params.is_edit = 0;//（是否编辑 1是 0否 默认0 is_edit、is_add 二者其一必填）
                            params.is_add = 1;//（是否添加 1是 0否 默认0 is_edit、is_add 二者其一必填）、
                        }
                        this.$http.api_user_fromaddresstoken(params).then((res)=>{
                            if(res){
                                if(res.code==1){
                                    if(res.data){
                                        this.addressParamsObj = res.data;
                                        this.inputaddressflag = true;
                                        // this.checkAddress = `${params.info}，${params.apartment}，${params.city}，${params.province}`;
                                        this.checkAddress = `${params.info}，${params.city}，${params.province}`;
                                    }
                                }else{
                                    ElementUI.Message({
                                        message:res.message,
                                        type:'error',
                                        center: true,
                                        offset:10,
                                    });
                                    return false;
                                }
                            }
                        });
                    }
                }else{
                    if(!this.orderno) return;
                    if(!this.addressParamsObj.id) return;
                    // 第二步：根据订单设置地址
                    this.$http.api_order_setadressOrder({
                        token:this.token,
                        orderno:this.orderno,
                        addressid:this.addressParamsObj.id
                    }).then((data)=>{
                        if(data){
                            if(data.code==1){
                                if(data.data){
                                    // 第三步：立即支付操作
                                    this.$http.api_order_payorder({
                                        token:this.token,//（用户token）、
                                        orderno:this.orderno,//（订单编号）、
                                        pricetype:this.radio,//（支付方式 1微信 2支付宝 3visa 4线下支付）、
                                        isMobile:tp?(tp=='web'?'0':'1'):'0',//（是否为手机端 1是 0否）
                                    }).then((res)=>{
                                        if(res){
                                            if(res.code==1){
                                                if(res.data){
                                                    // 移动端并且支付宝
                                                    // if(tp=='mobile'&&this.radio=='2'){
                                                    //     let resData = JSON.stringify(res.data);//JSON.stringify(res.data)//JSON.parse(JSON.stringify(res.data));//res.data;//
                                                    //     // const div = document.createElement('div');
                                                    //     // div.id = 'alipay';
                                                    //     // div.innerHTML = resData;
                                                    //     // document.body.querySelector('#app').appendChild(div);
                                                    //     // document.body.querySelector('#app').children[3])
                                                    //     // document.body.querySelector('#app').children[3].submit()
                                                    //     // document.querySelector('#alipay').children[0].submit();
                                                    // }else{
                                                        if(res.data.redirect_url){
                                                            // 跳转支付页面
                                                            window.location.href = res.data.redirect_url;
                                                        }else{
                                                            this.$router.push({path:'/myorderinfo',query:{order_no:this.orderno}});
                                                        }
                                                    // }
                                                }
                                            }else{
                                                ElementUI.Message({
                                                    message:res.message,
                                                    type:'error',
                                                    center: true,
                                                    offset:10,
                                                });
                                                return false;
                                            }
                                        }
                                    });
                                }
                            }else if(data.code==-1){
                                this.$store.commit('setLoginNameStatus', {});
                                delLocalItem('login_user_info');
                                this.$root.Bus.$emit('userInfoClick', {});
                                this.$store.commit('setLoginStatus',true);
                                this.$router.push({path:'/'});
                            }else{
                                ElementUI.Message({
                                    message:data.message,
                                    type:'error',
                                    center: true,
                                    offset:10,
                                });
                                return false;
                            }
                        }
                    });
                }
            }else{
                ElementUI.Message({
                    message:this.languageObj.logintip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }
        },
    },
    mounted(){
        this.languageName = localStorage.lang?localStorage.lang:yooz_lang;
        if(this.languageName=='cn'){
            this.languageObj = {
                "logintip":"请先登录",
                "areatip":"请选择国家/地区",
                "provincetip":"请输入省份",
                "nametip":"请输入您的名",
                "lasnametip":"请输入您的姓",
                "addresstip":"请输入您的地址",
                // "apartmenttip":"请输入公寓、门牌号等",
                // "doortip":"请输入门铃",
                "postcodetip":"请输入邮编 ",
                "telepstip":"请输入您的电话",
                "phonerighttip":"请输入正确的手机号",
                "citytip":"请输入您的城市",
                "province_codepost":"您输入的省份没有邮编",
                "codepost_province":"您输入的邮编没有省份",
            }
        }else if(this.languageName=='en'){
            this.languageObj = {
                "logintip":"please log in first",
                "areatip":"select a country",
                "provincetip":"enter province",
                "nametip":"enter your name",
                "lasnametip":"enter your last name",
                "addresstip":"enter your address",
                // "apartmenttip":"enter the apartment, house number, etc.",
                // "doortip":"enter the doorbell",
                "postcodetip":"enter zip code",
                "telepstip":"enter your phone",
                "phonerighttip":"Please enter the correct mobile number",
                "citytip":"enter your city",
                "province_codepost":"The province you entered does not have a zip code",
                "codepost_province":"The zip code you entered has no province",
            }
        }else if(this.languageName=='it'){
            this.languageObj = {
                "logintip":"fai prima il log in",
                "areatip":"seleziona un paese",
                "provincetip":"inserirsci la provincia",
                "nametip":"inserisci il tuo nome",
                "lasnametip":"inserisci il tuo cognome",
                "addresstip":"inserisci il tuo indirizzo",
                // "apartmenttip":"inserisci l'appartamento, il numero civico, ecc.",
                // "doortip":"inserisci il campanello",
                "postcodetip":"inserisci il codice postale",
                "telepstip":"inserisci il tuo telefono",
                "phonerighttip":"Inserisci il numero di cellulare corretto",
                "citytip":"inserisci la tua città",
                "province_codepost":"La provincia inserita non ha un codice postale",
                "codepost_province":"Il codice postale che hai inserito non ha provincia",
            }
        }
    },
    created(){
        let login_user_info = this.$store.state.data.loginNameStatus.family_name?this.$store.state.data.loginNameStatus:(getLocalItem('login_user_info')?getLocalItem('login_user_info'):null);
        if(login_user_info){
            // 再次判断是否是企业用户(是否企业身份 1是 0否)
            if(login_user_info.is_company==1){
                this.userPople='company';
            }
        }
        let token = getLocalItem('login_user_info')&&getLocalItem('login_user_info').token?getLocalItem('login_user_info').token:'';
        if(token){
            this.token = token;
            // this.getAddressDefault(token);// 查询收货默认展示收货地址信息
            // this.getAddresslist(1);//获取地址列表数据
            this.getPayTpye();// 获取支付类型
            this.getPayOther();// 获取支付类型的货到付款的手续费接口
            this.getQueryPostage();// 获取系统保底邮费和平台邮费
            this.getPackAmount();
            let routes = this.$route.query;//地址栏参数
            if(routes&&routes.orderno){
                this.orderno = routes.orderno;
                this.getOrderInfo(token,routes.orderno);// 获取订单详情
                if(routes.goodsid){
                    this.shopid = routes.goodsid;
                    this.getShopInfo(routes.goodsid);// 第一步：查询单个商品信息
                }
            }
        }else{
            this.$router.push({path:'/'});
            return false;
        }
    },
}
</script>
<style lang="scss" scoped>
.navtab{
    padding:20px 10px 10px;
    
    .infocur{
        color: #95959e;
    }
    .paycur{
        color: #DDE0ED;
    }
    .infocur_spet{
        cursor: pointer;
        color: #1f73b7;
    }
    .paycur_spet{
        color: #95959e;
    }
}
/deep/.el-breadcrumb__inner.is-link{
    color: #1f73b7;
    font-weight: normal;
}
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
    color: #95959e;
}
/deep/.ivu-date-picker{
    width: 50%;
    height: 56px;
}
/deep/.ivu-input{
    height: 56px;
    font-size:16px;
    padding:0 20px;
    border: 1px solid #DDE0ED;
}
/deep/.ivu-input-suffix{
    display: none;
}
/deep/.ivu-icon-ios-calendar-outline:before{
    content: '';
}
/deep/.ivu-date-picker-focused input{
    border-color: #DDE0ED;
    outline: 0;
    box-shadow: 0 0 0 2px transparent;
}
.pc-shopcar{
    padding:20px 0;
    .pc-shopwrap{
        width: 100%;
        display: flex;
        -webkit-box-orient: horizontal;
        .pc-shop{
            width: 40%;
            background: #f4f7fe;
            padding:20px 20px 40px;
            .pc-shop-group{
                width: 100%;
                display: flex;
                -webkit-box-orient: horizontal;
                padding:10px 0;
                .pc-shop-img{
                    width:20%;
                    img{
                        width: 100%;
                    }
                }
                .pc-shop-info{
                    width:80%;
                    padding-left:20px;
                    .pc-shop-title{
                        
                        line-height: 30px;
                    }
                    .pc-shop-numer{
                        color: #a4a4ad;
                        .pc-num{
                            display: inline-block;
                            margin-left: 10px;
                        }
                    }
                    .pc-shop-money{
                        margin-top:20px;
                        color: #bf0000;
                    }
                }
            }
            .pc-calcul{
                padding:20px 0px;
                border-top: 1px solid #dde0ed;
                border-bottom: 1px solid #dde0ed;
                .pc-calcul-group{
                    display: flex;
                    -webkit-box-orient: horizontal;
                    padding:5px 0;
                    .pc-calcul-txt{
                        width: 40%;
                        
                    }
                    .pc-calcul-num{
                        width: 60%;
                        text-align: right;
                        
                        .pc-allnum{
                            color: #9c9da5;
                            margin-left: 4px;
                            word-break: break-all;
                        }
                    }
                }
            }
            .pc-calcul-group{
                display: flex;
                -webkit-box-orient: horizontal;
                padding:15px 0px;
                .pc-calcul-txt{
                    width: 50%;
                    
                }
                .pc-calcul-nums{
                    width: 50%;
                    text-align: right;
                    font-size:18px;
                    
                }
            }
        }
        .pc-all{
            width: 60%;
            padding:0px 60px 40px;
            .pc-title{
                font-size:18px;
                padding-bottom: 10px;
            }
            .pc-select{
                width: 100%;
                /deep/.el-select{
                    width: 100%;
                }
            }
            .pc-input_odd{
                border:1px solid #eee;
                border-radius:5px;
                margin-bottom: 20px;
                background: #fff;
                .pc-pay-type{
                    padding:10px 0 0;
                    .pc-pay-otem{
                        padding:0px 15px 10px;
                        border-bottom:1px solid #eee;
                        position: relative;
                        span{
                            color: red;
                        }
                        .pc-icon-img{
                            position: absolute;
                            right:10px;
                            top:0;
                            display: inline-block;
                            width:70px;
                            height:30px;
                            .pc-payicon{
                                display: inline-block;
                                width: 30px;
                                height:22px;
                                margin-right: 10px;
                                vertical-align: middle;
                                &:last-child{
                                    margin-right: 0px;

                                }
                            }
                            
                            .pc-visa{
                                background: url(~@/assets/images/icon_visa.png) no-repeat left top;
                                background-size: 100% 100%;
                            }
                            .pc-load{
                                background: url(~@/assets/images/icon_mastercard.png) no-repeat left top;
                                background-size: 100% 100%;
                            }
                            
                        }
                        
                        /deep/.el-radio{
                            margin-right: 10px;
                        }
                        /deep/.el-radio__inner{
                            width:20px;
                            height:20px;
                        }
                        /deep/.el-radio__label{
                            
                        }
                        /deep/.el-radio__input.is-checked .el-radio__inner{
                            border-color: #1f73b7;
                            background: #1f73b7;
                        }
                        /deep/.el-radio__input.is-checked+.el-radio__label{
                            color: #222;
                        }
                    }
                    &:last-child{
                        .pc-pay-otem{
                            border-bottom:none;
                        }
                        
                    }
                    .pc-pay-input{
                        background: #f4f7fe;
                        padding:10px 20px;
                        .pc-input-two{
                            display: flex;
                            -webkit-box-orient: horizontal;
                            height: 58px;
                            width: 100%;
                            margin-bottom: 20px;
                            .pc-t{width:4%}
                            /deep/.el-input--prefix .el-input__inner{
                                padding-left: 20px;
                            }
                            .el-input{
                                width: 48%;
                                height: 100%;
                                /deep/.el-input__inner{
                                    height: 100%;
                                    color: #95959E;
                                    border:1px solid #DDE0ED;
                                }
                                
                                // WebKit 谷歌
                                /deep/input.el-input__inner::-webkit-input-placeholder {
                                    color: #95959E;
                                }
                                // Mozilla Firefox 4 - 18 适配火狐
                                /deep/input.el-input__inner:-moz-placeholder { 
                                    color: #95959E;
                                }
                                // Mozilla Firefox 19+ 适配火狐
                                /deep/input.el-input__inner::-moz-placeholder {
                                    color: #95959E;
                                }
                                // IE 10+  
                                /deep/input.el-input__inner:-ms-input-placeholder { 
                                    color: #95959E
                                }
                                /deep/.el-input__icon{display: none;}
                            }
                            input{
                                height: 100%;
                                width: 48%;
                                border:1px solid #DDE0ED;
                                outline: none;
                                padding:0 20px;
                                border-radius:5px;
                                background: #fff;
                                
                                // margin: 0 2%;
                            }
                            // WebKit 谷歌
                            /deep/input::-webkit-input-placeholder {
                                color: #95959E;
                            }
                            // Mozilla Firefox 4 - 18 适配火狐
                            /deep/input:-moz-placeholder { 
                                color: #95959E;
                            }
                            // Mozilla Firefox 19+ 适配火狐
                            /deep/input::-moz-placeholder {
                                color: #95959E;
                            }
                            // IE 10+  
                            /deep/input:-ms-input-placeholder { 
                                color: #95959E
                            }
                        }
                        .pc-input{
                            height: 58px;
                            width: 100%;
                            border:1px solid #DDE0ED;
                            margin-top: 20px;
                            border-radius:5px;
                            background: #fff;
                            input{
                                height: 100%;
                                width: 100%;
                                border: none;
                                outline: none;
                                padding:0 20px;
                                
                                background: transparent;
                            }
                            // WebKit 谷歌
                            /deep/input::-webkit-input-placeholder {
                                color: #95959E;
                            }
                            // Mozilla Firefox 4 - 18 适配火狐
                            /deep/input:-moz-placeholder { 
                                color: #95959E;
                            }
                            // Mozilla Firefox 19+ 适配火狐
                            /deep/input::-moz-placeholder {
                                color: #95959E;
                            }
                            // IE 10+  
                            /deep/input:-ms-input-placeholder { 
                                color: #95959E
                            }
                        }
                    }
                }
            }
            .pc-input{
                height: 58px;
                width: 100%;
                border:1px solid #DDE0ED;
                margin-bottom: 20px;
                border-radius:5px;
                position: relative;
                input{
                    height: 100%;
                    width: 100%;
                    border: none;
                    outline: none;
                    padding:0 50px 0 20px;
                    background: transparent;
                }
                .address-show{
                    height: 100%;
                    line-height: 58px;
                    padding:0 60px 0 20px;
                    overflow:hidden;
                    text-overflow:ellipsis;
                    word-break:keep-all;    
                    white-space:nowrap; 
                }
                .pc-update{
                    position: absolute;
                    top:0;
                    right: 2px;
                    cursor: pointer;
                    height: 54px;
                    padding:0 20px;
                    line-height: 58px;
                    color: #1f73b7;
                    background: #fff;
                }
            }
            .pc-link{
                height: 40px;
                width: 100%;
                text-align: right;
                .pc-linkbtn{
                    display: inline-block;
                    padding:12px 30px;
                    border-radius:4px;
                    background: #222;
                    color: #fff;
                    cursor: pointer;
                }
            }
        }
        .pc-all-address{
            width: 60%;
            padding:0px 60px 40px;
            .pc-title{
                font-size:18px;
                padding-bottom: 10px;
            }
            .pc-select{
                width: 100%;
                height:58px;
                margin-bottom: 20px;
                // padding:0 20px;
                border-radius:5px;
                border:1px solid #DDE0ED;
                position: relative;
                cursor: pointer;
                .pc-select{
                    display: flex;
                    height: auto;
                    width: 100%;
                    border: none;
                    outline: none;
                    padding: 0;
                    padding-left: 20px;
                    margin: 0 0px;
                    background: transparent;
                    appearance:none;   
                    -moz-appearance:none;   
                    -webkit-appearance:none; 
                }
                .pc-one_select{
                    height:58px;
                    width: 100%;
                    // border: none;
                    // outline: none;
                    // background: transparent;
                    // appearance:none;   
                    // -moz-appearance:none;   
                    // -webkit-appearance:none; 
                    position: relative;
                    .pc-one_select-name{
                        line-height:58px;
                        padding:0 40px 0 20px;
                        overflow:hidden;
                        text-overflow:ellipsis;
                        word-break:keep-all;    
                        white-space:nowrap; 
                    }
                    .pc-one_select-list{
                        position: absolute;
                        left: 0;
                        top:56px;
                        z-index: 1;
                        border:1px solid #DDE0ED;
                        background: #fff;
                        width: 100%;
                        padding:4px 10px;
                        div{
                            cursor: pointer;
                            padding:6px 10px;
                            border-bottom: 1px solid #DDE0ED;
                            // width: 100%;
                            // display: inline-block;
                            &:last-child{
                                border-bottom: none;
                            }
                        }
                    }

                }
                .el-icon-arrow-down{
                    position: absolute;
                    right: 20px;
                    top:20px;
                }
                .pc-select-tit{
                    width: 100%;
                    height:28px;
                    line-height:28px;
                    padding-left: 20px;
                    
                    color: #95959E;
                }
                /deep/.el-select{
                    width: 100%;
                }
            }
            .pc-input_odd{
                width: 100%;
                height:58px;
                margin-bottom: 20px;
            }
            .pc-input-two{
                width: 100%;
                height:58px;
                display: flex;
                -webkit-box-orient: horizontal;
                width: 100%;
                margin-bottom: 20px;
                .pc-t{width:4%}
                input{
                    height: 100%;
                    width: 48%;
                    border:1px solid #eee;
                    outline: none;
                    padding:0 20px;
                    border-radius:5px;
                    background: transparent;
                    // margin: 0 2%;
                }
                // WebKit 谷歌
                input::-webkit-input-placeholder {
                    color: #95959E;
                }
                // Mozilla Firefox 4 - 18 适配火狐
                input:-moz-placeholder { 
                    color: #95959E;
                }
                // Mozilla Firefox 19+ 适配火狐
                input::-moz-placeholder {
                    color: #95959E;
                }
                // IE 10+  
                input:-ms-input-placeholder { 
                    color: #95959E
                }
            }
            .pc-input{
                height:58px;
                width: 100%;
                border:1px solid #eee;
                margin-top: 20px;
                border-radius:5px;
                input{
                    height: 100%;
                    width: 100%;
                    border: none;
                    outline: none;
                    padding:0 20px;
                    // color: #95959E;
                    background: transparent;
                }
                // WebKit 谷歌
                input::-webkit-input-placeholder {
                    color: #95959E;
                }
                // Mozilla Firefox 4 - 18 适配火狐
                input:-moz-placeholder { 
                    color: #95959E;
                }
                // Mozilla Firefox 19+ 适配火狐
                input::-moz-placeholder {
                    color: #95959E;
                }
                // IE 10+  
                input:-ms-input-placeholder { 
                    color: #95959E;
                }
            }
            .pc-link{
                height: 40px;
                width: 100%;
                text-align: right;
                margin-top: 20px;
                .pc-linkbtn{
                    display: inline-block;
                    padding:15px 30px;
                    border-radius:4px;
                    background: #222;
                    color: #fff;
                    cursor: pointer;
                }
            }
        }
    }
}
.wap-shopcar{
    padding:0;
    width: 100%;
    .wap-shop-show{
        width: 100%;
        margin: 0;
        padding:10px ;
        background: #f4f7fe;
        .wap-shop-showbtn{
            color: #1f73b7;
            padding:0;
            .el-icon-arrow-down{
                display: inline-block;
                color: #1f73b7;
                margin-left: 4px;
            }
        }
        .wap-shop-showmoney{
            padding:0;
            text-align: right;
        }
    }
    .wap-shop-wrap{
        padding:10px;
        margin: 0;
        background: #f4f7fe;
        .wap-shop-group{
            width: 100%;
            display: flex;
            -webkit-box-orient: horizontal;
            padding:10px 0;
            .wap-shop-img{
                width:20%;
                img{
                    width: 100%;
                }
            }
            .wap-shop-info{
                width:80%;
                padding-left:20px;
                .wap-shop-title{
                    
                    line-height: 30px;
                }
                .wap-shop-numer{
                    color: #a4a4ad;
                    .wap-num{
                        float: right;
                        display: block;
                        margin-left: 10px;
                    }
                }
                .wap-shop-money{
                    margin-top:20px;
                    // color: #bf0000;
                }
            }
        }
        
        .wap-shop-data{
            width: 100%;
            padding:0px 0 20px;
            display: flex;
            -webkit-box-orient: vertical;
            .wap-shop-item{
                width: 100%;
                display: flex;
                -webkit-box-orient: horizontal;
                .wap-shop-img{
                    width:30%;
                    padding:0 10px 0 0;
                    img{width:100%}
                }
                .wap-shop-txt{
                    width:50%;
                    .title{
                        
                        padding:5px 0;
                    }
                    .txt{
                        
                        padding:0px 0 5px;
                        color: #95959e;
                    }
                    .shopnum{
                        
                        margin-top: 15px;
                    }
                }
                .wap-shop-shbtn{
                    width:20%;
                    text-align: right;

                    .wap-del-shopbtn{
                        text-align: right;
                        color: #95959e;
                        padding:10px 0px 0;
                    }
                }
            }
            
        }
        .wap-calcul{
            width: 100%;
            padding:20px 0px;
            border-top: 1px solid #dde0ed;
            border-bottom: 1px solid #dde0ed;
            .wap-calcul-group{
                width: 100%;
                display: flex;
                -webkit-box-orient: horizontal;
                padding:5px 0;
                .wap-calcul-txt{
                    width: 40%;
                }
                .wap-calcul-num{
                    width: 60%;
                    text-align: right;
                    .wap-allnum{
                        color: #9c9da5;
                        margin-left: 4px;
                    }
                }
            }
        }
        .wap-calcul-group{
            width: 100%;
            display: flex;
            -webkit-box-orient: horizontal;
            padding:15px 0px;
            .wap-calcul-txt{
                width: 40%;
            }
            .wap-calcul-nums{
                width: 60%;
                text-align: right;
                font-size:16px;
            }
        }
        
    }
    .wap-all{
        width: 100%;
        padding:20px 10px 40px;
        .wap-title{
            font-size:18px;
            padding-bottom: 10px;
        }
        .wap-select{
            width: 100%;
            /deep/.el-select{
                width: 100%;
            }
        }
        .wap-input_odd{
            border:1px solid #eee;
            border-radius:5px;
            margin-bottom: 20px;
            background: #fff;
            .wap-pay-type{
                padding:10px 0 0;
                .wap-pay-otem{
                    padding:0px 15px 10px;
                    border-bottom:1px solid #eee;
                    position: relative;
                    span{
                        color: red;
                    }
                    .wap-icon-img{
                        position: absolute;
                        right:10px;
                        top:0;
                        display: inline-block;
                        width:70px;
                        height:30px;
                        .wap-payicon{
                            display: inline-block;
                            width: 30px;
                            height:22px;
                            margin-right: 10px;
                            vertical-align: middle;
                            &:last-child{
                                margin-right: 0px;

                            }
                        }
                        
                        .wap-visa{
                            background: url(~@/assets/images/icon_visa.png) no-repeat left top;
                            background-size: 100% 100%;
                        }
                        .wap-load{
                            background: url(~@/assets/images/icon_mastercard.png) no-repeat left top;
                            background-size: 100% 100%;
                        }
                        
                    }
                    /deep/.el-radio{
                        margin-right: 10px;
                    }
                    /deep/.el-radio__inner{
                        width:20px;
                        height:20px;
                    }
                    /deep/.el-radio__label{
                        
                    }
                    /deep/.el-radio__input.is-checked .el-radio__inner{
                        border-color: #1f73b7;
                        background: #1f73b7;
                    }
                    /deep/.el-radio__input.is-checked+.el-radio__label{
                        color: #222;
                    }
                }
                &:last-child{
                    .wap-pay-otem{
                        border-bottom:none;
                    }
                    
                }
                .wap-pay-input{
                    background: #f4f7fe;
                    padding:10px 20px;
                    .wap-input-two{
                        display: flex;
                        -webkit-box-orient: horizontal;
                        height: 58px;
                        width: 100%;
                        margin-bottom: 20px;
                        .wap-t{width:4%}
                        /deep/.el-input--prefix .el-input__inner{
                            padding-left: 20px;
                        }
                        .el-input{
                            width: 48%;
                            height: 100%;
                            /deep/.el-input__inner{
                                height: 100%;
                                color: #95959E;
                                border:1px solid #DDE0ED;
                            }
                            
                            // WebKit 谷歌
                            /deep/input.el-input__inner::-webkit-input-placeholder {
                                color: #95959E;
                            }
                            // Mozilla Firefox 4 - 18 适配火狐
                            /deep/input.el-input__inner:-moz-placeholder { 
                                color: #95959E;
                            }
                            // Mozilla Firefox 19+ 适配火狐
                            /deep/input.el-input__inner::-moz-placeholder {
                                color: #95959E;
                            }
                            // IE 10+  
                            /deep/input.el-input__inner:-ms-input-placeholder { 
                                color: #95959E
                            }
                            /deep/.el-input__icon{display: none;}
                        }
                        input{
                            height: 100%;
                            width: 48%;
                            border:1px solid #DDE0ED;
                            outline: none;
                            padding:0 20px;
                            border-radius:5px;
                            background: #fff;
                            
                            // margin: 0 2%;
                        }
                        // WebKit 谷歌
                        /deep/input::-webkit-input-placeholder {
                            color: #95959E;
                        }
                        // Mozilla Firefox 4 - 18 适配火狐
                        /deep/input:-moz-placeholder { 
                            color: #95959E;
                        }
                        // Mozilla Firefox 19+ 适配火狐
                        /deep/input::-moz-placeholder {
                            color: #95959E;
                        }
                        // IE 10+  
                        /deep/input:-ms-input-placeholder { 
                            color: #95959E
                        }
                    }
                    .pc-input{
                        height: 58px;
                        width: 100%;
                        border:1px solid #DDE0ED;
                        margin-top: 20px;
                        border-radius:5px;
                        background: #fff;
                        input{
                            height: 100%;
                            width: 100%;
                            border: none;
                            outline: none;
                            padding:0 20px;
                            
                            background: transparent;
                        }
                        // WebKit 谷歌
                        /deep/input::-webkit-input-placeholder {
                            color: #95959E;
                        }
                        // Mozilla Firefox 4 - 18 适配火狐
                        /deep/input:-moz-placeholder { 
                            color: #95959E;
                        }
                        // Mozilla Firefox 19+ 适配火狐
                        /deep/input::-moz-placeholder {
                            color: #95959E;
                        }
                        // IE 10+  
                        /deep/input:-ms-input-placeholder { 
                            color: #95959E
                        }
                    }
                }
            }
        }
        .wap-input{
            height: 58px;
            width: 100%;
            border:1px solid #DDE0ED;
            margin-bottom: 20px;
            border-radius:5px;
            position: relative;
            input{
                height: 100%;
                width: 100%;
                border: none;
                outline: none;
                padding:0 50px 0 20px;
                background: transparent;
            }
            .address-show{
                height: 100%;
                line-height: 58px;
                padding:0 60px 0 20px;
                overflow:hidden;
                text-overflow:ellipsis;
                word-break:keep-all;    
                white-space:nowrap; 
            }
            .wap-update{
                position: absolute;
                top:0;
                right: 2px;
                cursor: pointer;
                height: 58px;
                padding:0 20px;
                line-height: 58px;
                color: #1f73b7;
                background: transparent;
            }
        }
        .wap-link{
            height: 40px;
            width: 100%;
            text-align: right;
            .wap-linkbtn{
                display: inline-block;
                padding:12px 30px;
                border-radius:4px;
                background: #222;
                color: #fff;
                cursor: pointer;
            }
        }
    }
    .wap-shop-post{
        padding:10px;
        margin: 0;
        .wap-title{font-size:20px;width:100%;padding:0 0 10px 0;}
        // .wap-input{
        //     width:100%;
        //     margin-bottom: 20px;
        //     input{
        //         border: 1px solid #95959e;
        //         outline: none;
        //         width:100%;
        //         padding:10px 10px;
        //         border-radius:4px;
        //     }
        //     button{
        //         border: none;
        //         outline: none;
        //         width:100%;
        //         padding:10px 10px;
        //         color: #fff;
        //         background: #222;
        //         border-radius:4px;
        //     }
        // }
        .wap-btn{
            padding:0 0 20px;
            width: 100%;
            button{
                border: none;
                outline: none;
                width:100%;
                padding:12px 10px;
                color: #fff;
                background: #222;
                border-radius:4px;
            }
        }
        .wap-input{
            width:100%;
            height:58px;
            margin-bottom: 20px;
            border-radius:4px;
            border: 1px solid #DDE0ED;
            position: relative;
            input{
                height:100%;
                border: none;
                outline: none;
                width:100%;
                padding:10px 20px;
                border-radius:4px;
                background: transparent;
            }
            .wap-upone{
                padding-right:80px;
            }
            // WebKit 谷歌
            input::-webkit-input-placeholder {
                color: #95959E;
            }
            // Mozilla Firefox 4 - 18 适配火狐
            input:-moz-placeholder { 
                color: #95959E;
            }
            // Mozilla Firefox 19+ 适配火狐
            input::-moz-placeholder {
                color: #95959E;
            }
            // IE 10+  
            input:-ms-input-placeholder { 
                color: #95959E
            }
            .wap-update{
                position: absolute;
                right: 0px;
                top: 0;
                height:58px;
                line-height:58px;
                padding:0 20px;
                display: block;
                color: #1F73B7;
                border-radius:4px;
            }
        }
        .wap-input_odd{
            width: 100%;
            border:1px solid #eee;
            border-radius:5px;
            margin-bottom: 20px;
            background: #fff;
            .wap-pay-type{
                padding:10px 0 0;
                .wap-pay-otem{
                    padding:0px 15px 10px;
                    border-bottom:1px solid #eee;
                    position: relative;
                    .wap-icon-img{
                        position: absolute;
                        right:10px;
                        top:0;
                        display: inline-block;
                        width:70px;
                        height:30px;
                        .wap-payicon{
                            display: inline-block;
                            width: 30px;
                            height:22px;
                            margin-right: 10px;
                            vertical-align: middle;
                            &:last-child{
                                margin-right: 0px;

                            }
                        }
                        
                        .wap-visa{
                            background: url(~@/assets/images/icon_visa.png) no-repeat left top;
                            background-size: 100% 100%;
                        }
                        .wap-load{
                            background: url(~@/assets/images/icon_mastercard.png) no-repeat left top;
                            background-size: 100% 100%;
                        }
                        
                    }
                    
                    /deep/.el-radio{
                        margin-right: 10px;
                    }
                    /deep/.el-radio__inner{
                        width:20px;
                        height:20px;
                    }
                    /deep/.el-radio__label{
                        
                    }
                    /deep/.el-radio__input.is-checked .el-radio__inner{
                        border-color: #1f73b7;
                        background: #1f73b7;
                    }
                    /deep/.el-radio__input.is-checked+.el-radio__label{
                        color: #222;
                    }
                }
                &:last-child{
                    .wap-pay-otem{
                        border-bottom:none;
                    }
                    
                }
                .wap-pay-input{
                    background: #f4f7fe;
                    padding:10px 20px;
                    .wap-input-two{
                        display: flex;
                        -webkit-box-orient: horizontal;
                        height: 40px;
                        width: 100%;
                        margin-bottom: 20px;
                        .t{width:4%}
                        input{
                            height: 100%;
                            width: 48%;
                            border:1px solid #eee;
                            outline: none;
                            padding:0 20px;
                            border-radius:5px;
                            background: #fff;
                            
                            // margin: 0 2%;
                        }
                        /deep/.el-input--prefix .el-input__inner{
                            padding-left: 20px;
                        }
                        .el-input{
                            width: 48%;
                            height: 100%;
                            /deep/.el-input__inner{
                                height: 100%;
                                color: #95959E;
                                border:1px solid #DDE0ED;
                            }
                            
                            // WebKit 谷歌
                            /deep/input.el-input__inner::-webkit-input-placeholder {
                                color: #95959E;
                            }
                            // Mozilla Firefox 4 - 18 适配火狐
                            /deep/input.el-input__inner:-moz-placeholder { 
                                color: #95959E;
                            }
                            // Mozilla Firefox 19+ 适配火狐
                            /deep/input.el-input__inner::-moz-placeholder {
                                color: #95959E;
                            }
                            // IE 10+  
                            /deep/input.el-input__inner:-ms-input-placeholder { 
                                color: #95959E
                            }
                            /deep/.el-input__icon{display: none;}
                        }
                        /*input{
                            height: 100%;
                            width: 48%;
                            border:1px solid #DDE0ED;
                            outline: none;
                            padding:0 20px;
                            border-radius:5px;
                            background: #fff;
                            
                            // margin: 0 2%;
                        }*/ 
                        // WebKit 谷歌
                        /deep/input::-webkit-input-placeholder {
                            color: #95959E;
                        }
                        // Mozilla Firefox 4 - 18 适配火狐
                        /deep/input:-moz-placeholder { 
                            color: #95959E;
                        }
                        // Mozilla Firefox 19+ 适配火狐
                        /deep/input::-moz-placeholder {
                            color: #95959E;
                        }
                        // IE 10+  
                        /deep/input:-ms-input-placeholder { 
                            color: #95959E
                        }
                    }
                    .wap-input{
                        height: 58px;
                        width: 100%;
                        border:1px solid #DDE0ED;
                        margin-top: 20px;
                        border-radius:5px;
                        background: #fff;
                        input{
                            height: 100%;
                            width: 100%;
                            border: none;
                            outline: none;
                            padding:0 20px;
                            
                            background: transparent;
                        }
                    }
                }
            }
        }
    }
    .pc-all-address{
        width: 100%;
        padding:20px 10px 40px;
        .pc-title{
            font-size:18px;
            padding-bottom: 10px;
        }
        .pc-select{
            width: 100%;
            height:58px;
            margin-bottom: 20px;
            // padding:0 20px;
            border-radius:5px;
            border:1px solid #DDE0ED;
            position: relative;
            cursor: pointer;
            .pc-select{
                display: flex;
                height: auto;
                width: 100%;
                border: none;
                outline: none;
                padding: 0;
                padding-left: 20px;
                margin: 0 0px;
                background: transparent;
                appearance:none;   
                -moz-appearance:none;   
                -webkit-appearance:none; 
            }
            .pc-one_select{
                height:58px;
                width: 100%;
                // border: none;
                // outline: none;
                // background: transparent;
                // appearance:none;   
                // -moz-appearance:none;   
                // -webkit-appearance:none; 
                position: relative;
                .pc-one_select-name{
                    line-height:58px;
                    padding:0 40px 0 20px;
                    overflow:hidden;
                    text-overflow:ellipsis;
                    word-break:keep-all;    
                    white-space:nowrap; 
                }
                .pc-one_select-list{
                    position: absolute;
                    left: 0;
                    top:56px;
                    z-index: 1;
                    border:1px solid #DDE0ED;
                    background: #fff;
                    width: 100%;
                    padding:4px 10px;
                    div{
                        cursor: pointer;
                        padding:6px 10px;
                        border-bottom: 1px solid #DDE0ED;
                        // width: 100%;
                        // display: inline-block;
                        &:last-child{
                            border-bottom: none;
                        }
                    }
                }

            }
            .el-icon-arrow-down{
                position: absolute;
                right: 20px;
                top:20px;
            }
            .pc-select-tit{
                width: 100%;
                height:28px;
                line-height:28px;
                padding-left: 20px;
                
                color: #95959E;
            }
            /deep/.el-select{
                width: 100%;
            }
        }
        .pc-input_odd{
            width: 100%;
            height:58px;
            margin-bottom: 20px;
        }
        .pc-input-two{
            width: 100%;
            height:58px;
            display: flex;
            -webkit-box-orient: horizontal;
            width: 100%;
            margin-bottom: 20px;
            .pc-t{width:4%}
            input{
                height: 100%;
                width: 48%;
                border:1px solid #eee;
                outline: none;
                padding:0 20px;
                border-radius:5px;
                background: transparent;
                // margin: 0 2%;
            }
            // WebKit 谷歌
            input::-webkit-input-placeholder {
                color: #95959E;
            }
            // Mozilla Firefox 4 - 18 适配火狐
            input:-moz-placeholder { 
                color: #95959E;
            }
            // Mozilla Firefox 19+ 适配火狐
            input::-moz-placeholder {
                color: #95959E;
            }
            // IE 10+  
            input:-ms-input-placeholder { 
                color: #95959E
            }
        }
        .pc-input{
            height:58px;
            width: 100%;
            border:1px solid #eee;
            margin-top: 20px;
            border-radius:5px;
            input{
                height: 100%;
                width: 100%;
                border: none;
                outline: none;
                padding:0 20px;
                // color: #95959E;
                background: transparent;
            }
            // WebKit 谷歌
            input::-webkit-input-placeholder {
                color: #95959E;
            }
            // Mozilla Firefox 4 - 18 适配火狐
            input:-moz-placeholder { 
                color: #95959E;
            }
            // Mozilla Firefox 19+ 适配火狐
            input::-moz-placeholder {
                color: #95959E;
            }
            // IE 10+  
            input:-ms-input-placeholder { 
                color: #95959E;
            }
        }
        .pc-link{
            height: 40px;
            width: 100%;
            text-align: right;
            margin-top: 20px;
            .pc-linkbtn{
                display: inline-block;
                padding:15px 30px;
                border-radius:4px;
                background: #222;
                color: #fff;
                cursor: pointer;
            }
        }
    }
}
@media screen and (min-width: 1200px) {
    .wrap{
        .wap-linkbtn{display: none;}
        .wap-shopcar{display: none;}
    }
}
@media screen and(min-width: 960px) and (max-width: 1199px) {
    .wrap{
        .wap-linkbtn{display: none;}
        .wap-shopcar{display: none;}
    }
}
@media screen and(min-width: 768px) and (max-width: 959px) {
    .wrap{
        .wap-linkbtn{display: none;}
        .wap-shopcar{display: none;}
    }
}
@media screen and(min-width: 480px) and (max-width: 767px) {
    .wrap{
        .pc-linkbtn{display: none;}
        .pc-shopcar{display: none;}
    }
}
@media screen and (max-width: 479px) {
    .wrap{
        .pc-linkbtn{display: none;}
        .pc-shopcar{display: none;}
    }
}
</style>